<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >
        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>


            <v-spacer> </v-spacer>
  
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
                <v-form
                    ref="form"
                    v-model="form"
                    @submit.prevent="submit"
                    class="pa-4 pt-6"
                >
                    <v-alert type="error" v-if="errMessage != ''">
                    {{errMessage}}
                    </v-alert>
 
                    <v-autocomplete
                        v-if="!cmpOptions.isEdit"
                        v-model="selectedItems"
                        :items="availableUsers"
                        item-text="username"
                        item-value="id"
                        chips
                        filled
                        multiple
                        label="Benutzer"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                            >
                            <v-icon class="mr-1">
                            mdi-account
                            </v-icon>
                            {{ data.item.username }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-icon class="mr-4">
                                    mdi-account
                                </v-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.username"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.town"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <v-spacer></v-spacer>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn
                        
                        outlined
                        @click="$refs.form.reset()"
                    >
                        Reset
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!form"
                        :loading="isLoading"
                        dark
                        color="primary"
                        depressed
                        type="submit"              
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
    // import ManagementService from "../../services/management.service";
    export default {
  
 
        props:{
            item: {
                required: false,
                type: Object,
                default: () => undefined
            },

            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                defaultOptions:{
                    title: 'Title',
                }, 

                isLoading: false,
                message: '',
                errMessage: '',
                form: false,
                selectedItems: undefined,    
             }
        },

        computed:{            
 
            currentUser() {
                return this.$store.state.auth.user;
            },

            availableUsers: function() {
                    return this.currentUser.linkedUsers;
            },

            cmpOptions(){
                //console.debug('cmpOptions');
                // console.debug('cmpOptions', {...this.defaultOptions,...this.options}, 'sds', this.currentUser);
                return {...this.defaultOptions,...this.options};
            }
 
        },

        methods:{ 


            close(event){
                console.debug('close', event)
                this.$emit('close', event)  
                this.$emit('update:isVisible', false)        
            },

            submit(event){
                console.debug('submit', event)
                const _this = this
                this.$store.dispatch('management/patchApiSetStore', {path:"api/management/company/users", param:{store:"companies", 'id': this.item.id,'users': this.selectedItems}})
                .then((response)=>{ 
                    console.log("submit:", response); 
                    this.$emit('close')  

                })
                .catch((error)=>{
                    _this.errMessage = error
                    console.error(error);
                })

                // ManagementService.patchitemUsers(this.item, this.selectedItems).then(
                //     (response) => {
                    
                //         console.log("itemUsersChanged:", response);
                        
                //         for(let val of this.availableUsers){
                //             const selectedindex = this.selectedItems.find( item => item == val.id);
                //             console.debug('index',index)
                //             const index = this.item.linkedUser.findIndex( item => item.id == val.id);
                //             if(!selectedindex && index != -1){
                //                 this.item.linkedUser.splice(index, 1)
                //             }
                //             else if(selectedindex && index == -1)
                //             {
                //                 this.item.linkedUser.push(val);
                //             }
                //             this.close(null);
                //             //if(index != -1)
                //             //    this.selecedCompanies.push(val.id)
                //         }
                        
                        
                //     },
                //     (error) => {
                //         this.errMessage =  ((error.response &&
                //             error.response.data &&
                //             error.response.data.message) ||
                //             error.message ||
                //             error.toString())
                //     }
                // );           
            },

            remove: function(item) {
                    //console.debug('remove', item);
                    //console.debug('remove', this.value);
                    const index = this.selectedItems.indexOf(item.id)
                    if (index >= 0) this.selectedItems.splice(index, 1)
            },

            getLinkedUsers(){
                console.debug('getLinkedUsers', this.item, 'curUser', this.currentUser)
                this.selectedItems = [];
                for(let val of this.availableUsers){
                    const index = this.item.linked_user.findIndex( item => item.id == val.id);
                    if(index != -1)
                        this.selectedItems.push(val.id)
                }
     
            },
 
        },
        watch: { 
            isVisible(newVal) { // watch it
                this.errMessage  = '';
                console.debug('isVisible');
                if(newVal){
                    this.getLinkedUsers();
                }
                console.log('Prop changed: isVisible', newVal)
            }
        },
        mounted() {
            this.errMessage  = '';
            console.debug('mounted');
            this.getLinkedUsers();
 
        }

    }

</script>